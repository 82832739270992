import constants from '@/libs/utils/constants'
import axios from '@axios'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    isEditable: false,
    filters: {
      search: '',
      status: constants.ticketStatus.Open,
    },
  },
  getters: {
    getIsEditable: state => state.isEditable,
  },
  mutations: {
    makeClientInfosEditable(state, isEditable = true) {
      state.isEditable = isEditable
    },
    setFilters(state, filters) {
      state.filters = filters
    },
  },
  actions: {
    SET_FILTERS({ commit }, filters) {
      commit('setFilters', filters)
    },
    GET_CLIENTS: (state, { paramsCampaign }) =>
      new Promise((resolve, reject) => {
        const params = {
          pageNumber: paramsCampaign.pageNumber,
          pageSize: paramsCampaign.pageSize,
          search: paramsCampaign.search,
          status: paramsCampaign.status,
        }
        Vue.prototype.$http
          .get('api/clients', { params })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_CLIENT: (state, { clientId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`api/clients/${clientId}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    CREATE_CLIENT: (state, { postClient }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post('/api/clients', postClient)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    MODIFY_CLIENT: (state, { clientId, patchData }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .patch(`/api/clients/${clientId}`, patchData)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_CLIENT_CONTACTS: (state, { clientId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`api/clients/${clientId}/contacts/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      }),
    CREATE_CLIENT_CONTACT: (state, { clientId, contact }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(`api/clients/${clientId}/contacts/`, contact)
          .then(response => resolve(response))
          .catch(error => reject(error))
      }),
    UPDATE_CLIENT_CONTACT: (state, { clientId, contactId, contact }) =>
      new Promise((resolve, reject) => {
        axios
          .patch(
            `/api/clients/${clientId}/contacts/${contactId}`,
            [
              {
                op: 'replace',
                path: '/fullName',
                value: contact.fullName,
              },
              {
                op: 'replace',
                path: '/title',
                value: contact.title,
              },
              {
                op: 'replace',
                path: '/phone',
                value: contact.phone,
              },
              {
                op: 'replace',
                path: '/email',
                value: contact.email,
              },
            ],
            {
              headers: {
                'Content-Type': 'application/json-patch+json',
              },
            },
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      }),
    DELETE_CLIENT_CONTACT: (state, { clientId, contactId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .delete(`api/clients/${clientId}/contacts/${contactId}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_FILES: (state, { clientId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/clients/${clientId}/files`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    ADD_FILE: (state, { clientId, fileName, type }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(`/api/clients/${clientId}/files`, { fileName, type })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_INVOICES: (state, { clientId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`api/clients/${clientId}/invoices`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_COMMENTS: (state, { clientId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`api/clients/${clientId}/comments`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    ADD_COMMENT: (state, { clientId, postComment }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(`api/clients/${clientId}/comments`, postComment)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    MODIFY_COMMENT: (state, { clientId, commentId, postComment }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put(`api/clients/${clientId}/comments/${commentId}`, postComment)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    DELETE_COMMENT(state, { clientId, commentId }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .delete(`api/clients/${clientId}/comments/${commentId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
