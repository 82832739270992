// import constants from '@/libs/utils/constants'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    contractFilters: {
      search: '',
      modalityFilter: null,
      sortDesc: true,
      sortBy: null,
    },
  },
  getters: {},
  mutations: {
    setContractFilters(state, filters) {
      state.filters = filters
    },
  },
  actions: {
    SET_CONTRACT_FILTERS({ commit }, filters) {
      commit('setContractFilters', filters)
    },
    GET_STATS: (state, { params }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/contracts/stats', { params })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_CONTRACTS: (state, { params }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/contracts', { params })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_CAMPAIGNS: (state, { params }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get('/api/contracts/campaigns', { params })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    CREATE_CONTRACT: (state, { contract }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post('/api/contracts', contract)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    UPDATE_CONTRACT: (state, { contractId, contract }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put(`/api/contracts/${contractId}`, contract)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_INVOICES: (state, { contractId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/contracts/${contractId}/invoices`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_INVOICE: (state, { contractId, invoiceId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/contracts/${contractId}/invoices/${invoiceId}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_INVOICE_ITEMS: (state, { contractId, invoiceId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/contracts/${contractId}/invoices/${invoiceId}/items`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_INVOICE_MEETINGS: (
      state,
      {
        contractId,
        invoiceId,
        stateFilter,
        statusFilter,
        shouldPaginate,
        pageNumber,
        pageSize,
        sortBy,
        sortDesc,
      },
    ) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/contracts/${contractId}/invoices/${invoiceId}/meetings`, {
            params: {
              stateFilter,
              statusFilter,
              shouldPaginate,
              pageNumber,
              pageSize,
              sortBy,
              sortDesc,
            },
          })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    OPEN_NEW_INVOICE: (state, { contractId, startDate }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(`/api/contracts/${contractId}/invoices/open`, { startDate })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    CLOSE_INVOICE: (state, { contractId, invoiceId, invoiceData }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(`/api/contracts/${contractId}/invoices/${invoiceId}/close`, invoiceData)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    UPDATE_INVOICE_HOURS: (state, { contractId, invoiceId, data }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put(`/api/contracts/${contractId}/invoices/${invoiceId}/hours`, data)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    GET_CONTRACT_NOTES: (state, { contractId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/contracts/${contractId}/notes`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    CREATE_CONTRACT_NOTE: (state, { contractId, noteData }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(`/api/contracts/${contractId}/notes`, noteData)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    UPDATE_INVOICE: (state, { contractId, invoiceId, invoiceData }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put(`/api/contracts/${contractId}/invoices/${invoiceId}`, invoiceData)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    ARCHIVE_CONTRACT: (state, { contractId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .delete(`/api/contracts/${contractId}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
    RESTORE_CONTRACT: (state, { contractId, restoreNotes, restoreInvoices, restoreInvoiceHours }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(`/api/contracts/${contractId}/restore`, {
            restoreNotes,
            restoreInvoices,
            restoreInvoiceHours,
          })
          .then(res => resolve(res))
          .catch(err => reject(err))
      }),
  },
}
