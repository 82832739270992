import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    actualProspect: {},
    isCurrentlyLoadingNextProspect: false,
    isEditable: false,
    prospectsType: -1,
  },
  getters: {
    getIsEditable: state => state.isEditable,
    prospectInfo(state) {
      return {
        prospectId: state.actualProspect.prospectId,
        campaignLnkProspectId: state.actualProspect.campaignLnkProspectId,
        name: state.actualProspect.name,
        address: state.actualProspect.address,
        suite: state.actualProspect.suite,
        city: state.actualProspect.city,
        region: state.actualProspect.region,
        postalCode: state.actualProspect.postalCode,
        province: state.actualProspect.province,
        latitude: state.actualProspect.latitude,
        longitude: state.actualProspect.longitude,
        phone: state.actualProspect.phone,
        numberOfEmployees: state.actualProspect.numberOfEmployees,
        lastModifiedEmployeeNumber: state.actualProspect.lastModifiedEmployeeNumber,
        turnover: state.actualProspect.turnover,
        sic: state.actualProspect.sic,
        fic: state.actualProspect.fic,
        website: state.actualProspect.website,
        clientId: state.actualProspect.clientId,
        clientName: state.actualProspect.clientName,
        campaignId: state.actualProspect.campaignId,
        campaignName: state.actualProspect.campaignName,
        scheduleCampaignNote: state.actualProspect.scheduleCampaignNote,
        followUpDate: state.actualProspect.followUpDate,
        reminderDate: state.actualProspect.reminderDate,
        defaultLanguage: state.actualProspect.defaultLanguage,
        isReadOnly: state.actualProspect.isReadOnly
      }
    },
    prospectContacts: state => state.actualProspect.contacts,
    commHistory: state => state.actualProspect.commHistory,
    resources: state => state.actualProspect.resources,
  },
  mutations: {
    storeProspect(state, { prospectDef }) {
      state.isEditable = false
      state.actualProspect = prospectDef
    },
    /**
     * Only override the prospect information
     * (NOT the informations concerning the campaignLnkProspect, I.E. contacts, history, etc)
     * @param {*} state The vuex state to modify the state from
     * @param {prospectInfo, type} param the return prospectInfo from a put on /info
     * call and the type of the prospect to modify
     */
    storeProspectInfo(state, { prospectInfo }) {
      state.isEditable = false

      state.actualProspect.name = prospectInfo.name
      state.actualProspect.address = prospectInfo.address
      state.actualProspect.suite = prospectInfo.suite
      state.actualProspect.city = prospectInfo.city
      state.actualProspect.region = prospectInfo.region
      state.actualProspect.postalCode = prospectInfo.postalCode
      state.actualProspect.province = prospectInfo.province
      state.actualProspect.phone = prospectInfo.phone
      state.actualProspect.numberOfEmployees = prospectInfo.numberOfEmployees
      state.actualProspect.lastModifiedEmployeeNumber = prospectInfo.lastModifiedEmployeeNumber
      state.actualProspect.turnover = prospectInfo.turnover
      state.actualProspect.sic = prospectInfo.sic
      state.actualProspect.fic = prospectInfo.fic
      state.actualProspect.website = prospectInfo.website
    },
    makeWorksheetEditable(state, isEditable = true) {
      state.isEditable = isEditable
    },
    changeReminderDate(state, { reminderDate }) {
      state.actualProspect.reminderDate = reminderDate
    },
    setProspectsType(state, prospectsType) {
      state.prospectsType = prospectsType
    },
  },
  actions: {
    PUNCH_IN: (state, { campaignLnkId }) =>
      new Promise((resolve, reject) => {
        if (!campaignLnkId) {
          return reject(new Error('Null parameter passed as campaignLnkId'))
        }
        return Vue.prototype.$http
          .post('/api/punch/worksheet/in', {
            campaignLnkProspectId: campaignLnkId,
          })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      }),
    PUNCH_OUT: () =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post('/api/punch/worksheet/out')
          .then(() => resolve())
          .catch(err => reject(err))
      }),
    GET_PROSPECT_INFO: ({ commit }, { campaignLnkId }) =>
      new Promise((resolve, reject) => {
        if (!campaignLnkId) {
          return reject(new Error('The campaignLnkId is null'))
        }
        return Vue.prototype.$http
          .get(`/api/worksheets/${campaignLnkId}`)
          .then(res => {
            commit('storeProspect', { prospectDef: res.data.data })
            return resolve(res)
          })
          .catch(err => reject(err))
      }),
    GET_TECHNICAL_SHEET: (state, { campaignLnkId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(`/api/worksheets/${campaignLnkId}/technical-sheet`)
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      }),
    CREATE_PROSPECT: (state, prospectInfo) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post('/api/worksheets', prospectInfo)
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      }),
    COPY_WORKSHEET: (state, { worksheetId, campaignId, employeeId, followUpDate }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(`/api/worksheets/${worksheetId}/copy`, {
            campaignId,
            employeeId,
            followUpDate,
          })
          .then(() => resolve())
          .catch(err => reject(err))
      }),
    ASSIGN_PROSPECT: (state, { worksheetId, campaignId, employeeId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(`/api/worksheets/${worksheetId}/assign`, {
            campaignId,
            employeeId,
          })
          .then(() => resolve())
          .catch(err => reject(err))
      }),
    REMOVE_PROSPECT: (state, { worksheetId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .delete(`/api/worksheets/${worksheetId}/remove`)
          .then(() => resolve())
          .catch(err => reject(err))
      }),
    SEND_WARNING_EMAIL: (state, { worksheetId }) =>
      new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(`/api/worksheets/${worksheetId}/warning-email`)
          .then(() => resolve())
          .catch(err => reject(err))
      }),
  },
}
