import { render, staticRenderFns } from "./SearchInput.vue?vue&type=template&id=5cb8ddf6&scoped=true"
import script from "./SearchInput.vue?vue&type=script&lang=js"
export * from "./SearchInput.vue?vue&type=script&lang=js"
import style0 from "./SearchInput.vue?vue&type=style&index=0&id=5cb8ddf6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cb8ddf6",
  null
  
)

export default component.exports